<template>
    <div v-if="popup && shows" class="popup-layer" :style="popupPosition">
        <v-card :href="popup.url" target="_blank" tile color="white" class="popup-layer__body" :style="popupSize">
            <div class="popup-layer__content" v-html="popup.content.replace(/\n/g, '<br>')"></div>
        </v-card>
        <div class="popup-layer__foot">
            <v-row no-gutters align="center">
                <v-col cols="auto">
                    <v-checkbox v-model="closeForToday" label="Close for today" hide-details dense />
                </v-col>
                <v-spacer />
                <v-col cols="auto">
                    <v-btn text @click="close"> close </v-btn>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        popup: { type: Object, default: null },
    },
    data: () => ({
        shows: false,
        closeForToday: false,
    }),
    computed: {
        popupPosition() {
            return {
                left: this.popup.left + "px",
                top: this.popup.top + "px",
            };
        },
        popupSize() {
            return {
                width: this.popup.width + "px",
                height: this.popup.height + "px",
            };
        },
    },
    mounted() {
        this.shows = !this.$cookies.get(this.popup?._id);
    },
    methods: {
        close() {
            this.shows = false;
            if (this.closeForToday) this.$cookies.set(this.popup._id, "hidden", "1d");
        },
    },
};
</script>
<style lang="scss" scoped>
.popup-layer {
    position: absolute;
    z-index: 10000;
    animation: popup-fade 0.35s ease-out 0.35s forwards;
    opacity: 0;
    &__body {
        height: auto !important;
    }
    &__foot {
        padding: 0 12px;
        color: #fff !important;
        background-color: #666;
        ::v-deep {
            .v-input--checkbox .theme--light.v-label,
            .v-input--checkbox .theme--light.v-icon,
            .v-btn {
                color: #fff !important;
            }
            .v-input--checkbox .theme--light.v-label,
            .v-btn {
                font-size: 14px;
            }
            .v-btn {
                height: 40px !important;
                padding: 0 12px !important;
                margin-right: -12px !important;
            }
        }
    }
    img {
        max-width: 100%;
    }
    ::v-deep {
        .v-input--selection-controls__input {
            margin-right: 4px;
        }
    }
}
@media (max-width: 768px) {
    .popup-layer {
        top: 80px !important;
        left: 0 !important;
        width: calc(100vw - 24px) !important;
        height: auto !important;
        margin: 0 12px;
        &__body {
            width: 100% !important;
            height: auto !important;
        }
    }
}
@keyframes popup-fade {
    0% {
        opacity: 0;
        -webkit-transform: translateY(25px);
        transform: translateY(25px);
    }
    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}
</style>
