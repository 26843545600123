var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.popup && _vm.shows ? _c('div', {
    staticClass: "popup-layer",
    style: _vm.popupPosition
  }, [_c('v-card', {
    staticClass: "popup-layer__body",
    style: _vm.popupSize,
    attrs: {
      "href": _vm.popup.url,
      "target": "_blank",
      "tile": "",
      "color": "white"
    }
  }, [_c('div', {
    staticClass: "popup-layer__content",
    domProps: {
      "innerHTML": _vm._s(_vm.popup.content.replace(/\n/g, '<br>'))
    }
  })]), _c('div', {
    staticClass: "popup-layer__foot"
  }, [_c('v-row', {
    attrs: {
      "no-gutters": "",
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-checkbox', {
    attrs: {
      "label": "Close for today",
      "hide-details": "",
      "dense": ""
    },
    model: {
      value: _vm.closeForToday,
      callback: function ($$v) {
        _vm.closeForToday = $$v;
      },
      expression: "closeForToday"
    }
  })], 1), _c('v-spacer'), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": _vm.close
    }
  }, [_vm._v(" close ")])], 1)], 1)], 1)], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }